import React, { useEffect, useState } from "react";
import { UpdateVehicleType as DomainUpdateVehicleType, LoadVehicleType } from "@/domain/usecase";
import { Button, Modal, FormControl, Messages, Input, IconButton, ProgressIndicator } from "fiorde-fe-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EditIcon } from "../icons";
import { t } from "i18next";
import {  PREFIX_VEHICLETYPE_UPDATEVEHICLETYPE_INPUT_NAME,
  PREFIX_VEHICLETYPE_UPDATEVEHICLETYPE_BUTTON_CLOSE,
  PREFIX_VEHICLETYPE_UPDATEVEHICLETYPE_BUTTON_SUBMIT,
  PREFIX_VEHICLETYPE_UPDATEVEHICLETYPE_INPUT_NAME_LABEL
} from "@/ids";

type UpdateVehicleTypeProps = {
  updateVehicleType: DomainUpdateVehicleType
  loadVehicleType: LoadVehicleType
  id: number
}

export function UpdateVehicleType({ updateVehicleType, loadVehicleType, id }: UpdateVehicleTypeProps) {
  const queryClient = useQueryClient()
  const [txVehicleType, setTxVehicleType] = useState("")
  const [open, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  });

  const { data } = useQuery<any>({
    queryKey: [`vehicle-type-${id}`],
    queryFn: async () => await loadVehicleType.load(id),
    enabled: open
  })

  function handleSubmit (): void {
    setLoading(true)
    updateVehicleType.update(id, {
      ...data, txVehicleType: txVehicleType })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["vehicle-type-list"] });
        handleClose();
        setResponseMessage({
          message: t("components.update-vehicle-type.response-success-message"),
          severity: "success"
        })
      })
      .catch(err => {
        setResponseMessage({
          message: err?.message,
          severity: "error"
        })
      })
      .finally((() => {
        setLoading(false)
      }))
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false)
    queryClient.invalidateQueries({ queryKey: [`vehicle-type-${id}`] })
  }

  useEffect(() => {
    if (open) {
      queryClient.invalidateQueries({ queryKey: [`vehicle-type-${id}`]})
    }
  }, [open]);

  useEffect(() => {
    setTxVehicleType(data?.txVehicleType)
  }, [data])

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      <IconButton
        data-testid="open-button"
        onClick={handleOpen}
        tooltip={t("components.update-vehicle-type.open-button-tooltip")}
      >
        <EditIcon />
      </IconButton>
      <Modal
        width={600}
        title={t("components.update-vehicle-type.title")}
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_VEHICLETYPE_UPDATEVEHICLETYPE_BUTTON_CLOSE}
            data-testid='close-button'
            backgroundGreen={false}
            noBackground
            onAction={() => handleClose()}
            text={t("components.update-vehicle-type.close-button")}
          />,
          <Button
            id={PREFIX_VEHICLETYPE_UPDATEVEHICLETYPE_BUTTON_SUBMIT}
            data-testid='submit-button'
            onAction={() => handleSubmit()}
            text={t("components.update-vehicle-type.submit-button")}
          />
        ]}
      >
        <FormControl
          label={t("components.update-vehicle-type.name-label")}
          id={PREFIX_VEHICLETYPE_UPDATEVEHICLETYPE_INPUT_NAME_LABEL}
        >
          <Input
            id={PREFIX_VEHICLETYPE_UPDATEVEHICLETYPE_INPUT_NAME}
            inputProps={{ "data-testid": "txVehicleType" }}
            onChange={(value) => setTxVehicleType(value)}
            value={txVehicleType}
            placeholder={t("components.update-vehicle-type.name-placeholder")}
          />
        </FormControl>
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
}
