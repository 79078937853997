import React, { useEffect, useState } from "react"
import {
  UpdateBusinessPartner as DomainUpdateBusinessPartner,
  LoadAddressTypeList,
  LoadBusinessPartner,
  LoadCitiesList,
  LoadClassificationList,
  LoadCountriesList,
  LoadDocumentTypeList,
  LoadStatesList
} from "@/domain/usecase"
import {
  Button,
  Modal,
  FormControl,
  Input,
  Messages,
  Tab,
  Select,
  Switch,
  Table,
  LabelCell,
  IconButton,
  ProgressIndicator
} from "fiorde-fe-components"
import { useQueryClient, useQuery } from "@tanstack/react-query"
import { makeSelectValues } from "@/presentation/utils/arrays"
import { Row } from "../row/row"
import { EditIcon, TrashIcon } from "../icons"
import { addClassification, filteredUnselectedClassificationList, disableSubmit, filteredSelectedClassificationList, handleAddForm, handleCloseForm, handleEditAddress, handleOpen, handleRemoveAddress, filteredContacts, handleAddContactForm, handleCloseContactForm, handleEditContact, handleRemoveContact } from "./business-partner-helpers"
import { BusinessPartnerForm } from "./business-partner-form"
import { BusinessPartnerAddressForm } from "./business-partner-address-form"
import { BusinessPartnerAddressColumns } from "./business-partner-address-columns"
import { t } from "i18next"
import { BusinessPartnerContactForm } from "./business-partner-contacts-form"
import { BusinessPartnerContactColumns } from "./business-partner-contacts-columns"
import { PREFIX_BUSINESSPARTNER_UPDATEBUSINESSPARTNER_BUTTON_CLOSE,
  PREFIX_BUSINESSPARTNER_UPDATEBUSINESSPARTNER_BUTTON_SUBMIT,
  PREFIX_BUSINESSPARTNER_UPDATEBUSINESSPARTNER_BUTTON_ADDADDRESS,
  PREFIX_BUSINESSPARTNER_UPDATEBUSINESSPARTNER_BUTTON_ADDCONTACT
 } from "@/ids"

type UpdateBusinessPartnerProps = {
  id: number
  loadBusinessPartner: LoadBusinessPartner
  updateBusinessPartner: DomainUpdateBusinessPartner
  loadClassificationList: LoadClassificationList
  loadCountriesList: LoadCountriesList
  loadAddressTypeList: LoadAddressTypeList
  loadDocumentTypeList: LoadDocumentTypeList
  loadCitiesList: LoadCitiesList
  loadStatesList: LoadStatesList
}

export function UpdateBusinessPartner({
  id,
  loadBusinessPartner,
  updateBusinessPartner,
  loadClassificationList,
  loadCountriesList,
  loadCitiesList,
  loadStatesList,
  loadAddressTypeList,
  loadDocumentTypeList
}: UpdateBusinessPartnerProps) {
  const queryClient = useQueryClient()
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  })
  const [open, setOpen] = useState(false)
  const [visibleForm, setVisibleForm] = useState(false)
  const [visibleContactForm, setVisibleContactForm] = useState(false)
  const [contactForm, setContactForm] = useState({
    index: null,
    name: '',
    email: '',
    phone: '',
    isActive: true
  })
  const [selectedTab, setSelectedTab] = useState(null)
  const [addressForm, setAddressForm] = useState({
    index: null,
    postalCode: '',
    number: null,
    name: '',
    neighborhood: '',
    complement: '',
    cityId: null,
    stateId: null,
    countryId: null,
    addressType: null
  })
  const [values, setValues] = useState({
    simpleName: '',
    document: '',
    documentType: { id: null },
    corporateName: '',
    reference: '',
    cityId: null,
    stateId: null,
    countryId: null,
    isHeadOffice: null,
    classifications: [],
    addresses: []
  })

  const { isLoading, data } = useQuery({
    queryKey: [`business-partner-${id}`],
    queryFn: async () => await loadBusinessPartner.load(id),
    enabled: open
  })

  const { data: documentTypeList = [] } = useQuery({
    queryKey: ['document-type-list'],
    queryFn: async () => await loadDocumentTypeList.load(),
    enabled: open
  })

  const { data: addressTypeList = [] } = useQuery({
    queryKey: ['address-type-list'],
    queryFn: async () => await loadAddressTypeList.load(),
    enabled: open
  })

  const { data: classificationList = [] } = useQuery({
    queryKey: ['classification-list'],
    queryFn: async () => await loadClassificationList.load(),
    enabled: open
  })

  const { data: countriesList = [] } = useQuery<any>({
    queryKey: ['countries-list'],
    queryFn: async () => await loadCountriesList.load(),
    enabled: open
  })

  const { data: citiesList = [] } = useQuery<any>({
    queryKey: ['cities-list', values.stateId],
    queryFn: async () => await loadCitiesList.load(values.stateId),
    enabled: open && !!values.stateId
  })

  const { data: statesList = [] } = useQuery<any>({
    queryKey: ['states-list', values.countryId],
    queryFn: async () => await loadStatesList.load(values.countryId),
    enabled: open && !!values.countryId
  })

  function handleSubmit(): void {
    updateBusinessPartner
      .update(
        id,
        {
          ...data,
          ...values
        }
      )
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["business-partner-list"] })
        handleClose()
        setResponseMessage({
          message: t("components.update-business-partner.response-success-message"),
          severity: "success",
        })
      })
      .catch((err) => {
        setResponseMessage({
          message: err?.message,
          severity: "error",
        })
      })
  }

  useEffect(() => {
    setValues({...data})
    if (data?.classifications?.length) {
      setSelectedTab(data.classifications[0]?.classification.id)
    }
  }, [data])

  useEffect(() => {
    if (!filteredSelectedClassificationList(classificationList, values).some(classification => classification?.id === selectedTab)) {
      setSelectedTab(values.classifications?.[0]?.classification?.id)
    }
  }, [values])

  function handleClose() {
    setResponseMessage({
      message: "",
      severity: "",
    })
    handleCloseForm(setAddressForm, setVisibleForm)
    setOpen(false)
  }

  return (
    <>
      <ProgressIndicator
        visible={isLoading && open}
        content={t("components.progress-indicator.content")}
      />
      <IconButton
        data-testid="open-button"
        onClick={() => handleOpen(setOpen)}
        tooltip={t("components.update-business-partner.open-button-tooltip")}
      >
        <EditIcon />
      </IconButton>
      <Modal
        data-testid="modal-update-business-partner"
        width={600}
        title={t("components.update-business-partner.title")}
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_BUSINESSPARTNER_UPDATEBUSINESSPARTNER_BUTTON_CLOSE}
            backgroundGreen={false}
            noBackground
            onAction={handleClose}
            text={t("components.update-business-partner.close-button")}
          />,
          <Button
            id={PREFIX_BUSINESSPARTNER_UPDATEBUSINESSPARTNER_BUTTON_SUBMIT}
            data-testid="submit-button"
            disabled={disableSubmit(values)}
            onAction={() => handleSubmit()}
            text={t("components.update-business-partner.submit-button")}
          />,
        ]}
      >
        <BusinessPartnerForm
          values={values}
          onChange={(value) => setValues(value)}
          classificationList={classificationList}
          documentTypeList={documentTypeList}
          countriesList={countriesList}
          statesList={statesList}
          citiesList={citiesList}
        />
        <strong>{t("components.update-business-partner.address-title")}</strong>
        <div style={{ margin: '20px 0px 0px 0px' }}>
          {visibleForm || !values?.addresses?.length
            ? (
              <BusinessPartnerAddressForm
                values={addressForm}
                onChange={(value) => setAddressForm(value)}
                addressTypeList={addressTypeList}
                onSubmit={() => handleAddForm(addressForm, values, setValues, () => handleCloseForm(setAddressForm, setVisibleForm))}
                onClose={() => handleCloseForm(setAddressForm, setVisibleForm)}
                disabledCloseButton={!values?.addresses?.length}
                addresses={values.addresses}
              />
            )
            : (
              <>
                <Table
                  rows={values.addresses || []}
                  columns={BusinessPartnerAddressColumns({
                    addressTypeList: addressTypeList,
                    edit: (address, index) => handleEditAddress(address, index, setAddressForm, setVisibleForm),
                    remove: (index) => handleRemoveAddress(values, index, setValues)
                  })}
                  size='small'
                />
                <Button
                  id={PREFIX_BUSINESSPARTNER_UPDATEBUSINESSPARTNER_BUTTON_ADDADDRESS}
                  backgroundGreen={false}
                  onAction={() => setVisibleForm(true)}
                  text="Novo endereço"
                  disabled={visibleForm}
                />
              </>
              )
          }
        </div>
        <Tab
          onChange={(value) => setSelectedTab(value)}
          value={selectedTab}
        >
          {filteredSelectedClassificationList(classificationList, values)?.map(classification => 
            <Tab.Item
              key={classification.id}
              value={classification.id}
              title={classification.name}
              data-testid={`tab-${classification}`}
            >
              <strong>Contatos</strong>
              <div style={{ margin: '20px 0px 0px 0px' }}>
                {visibleContactForm || !filteredContacts(classification.id, values.classifications).length
                  ? (
                    <BusinessPartnerContactForm
                      values={contactForm}
                      onChange={(value) => setContactForm(value)}
                      onSubmit={() => handleAddContactForm(contactForm, values, setValues, classification.id, () => handleCloseContactForm(setContactForm, setVisibleContactForm))}
                      onClose={() => handleCloseContactForm(setContactForm, setVisibleContactForm)}
                      disabledCloseButton={!filteredContacts(classification.id, values.classifications).length}
                    />
                  )
                  : (
                    <>
                      <Table
                        rows={filteredContacts(classification.id, values.classifications) || []}
                        columns={BusinessPartnerContactColumns({
                          edit: (contact, index) => handleEditContact(contact, index, setContactForm, setVisibleContactForm),
                          remove: (index) => handleRemoveContact(classification.id,values, index, setValues)
                        })}
                        size='small'
                      />
                      <Button
                        id={PREFIX_BUSINESSPARTNER_UPDATEBUSINESSPARTNER_BUTTON_ADDCONTACT}
                        backgroundGreen={false}
                        onAction={() => setVisibleContactForm(true)}
                        text={t("components.update-business-partner.add-contact")}
                        disabled={visibleContactForm}
                      />
                    </>
                  )
                }
              </div>
            </Tab.Item>
          )}
        </Tab>
        
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </>
  )
}
