import { ADDRESS_TYPE } from "@/main/enums/address-type"

export function disableSubmit (values) {
  if (!values.simpleName) return true
  if (!values.document) return true
  if (!values.documentType?.id) return true
  if (!values.corporateName) return true
  if (!values.classifications.length) return true
  if (!values.addresses.length) return true
}

export function disableAddForm (addressForm) {
  if (!addressForm.postalCode) return true
  if (!addressForm.addressType?.id) return true
  if (!addressForm.name) return true
  if (!addressForm.number) return true
}

export function disableAddContactForm (contactForm) {
  if (!contactForm.name) return true
  if (!contactForm.phone) return true
  if (!contactForm.email) return true
}

export function filteredSelectedClassificationList (classificationList, values) {
  return classificationList?.filter(classification =>
    values?.classifications?.some(item => item.classification.id === classification.id)
  )
}

export function filteredUnselectedClassificationList (classificationList, values) {
  return classificationList?.filter(classification =>
    !values?.classifications?.some(item => item.classification.id === classification.id)
  )
}

export function addClassification(values, newClassification, setValues) {
  const currentClassificationsIds = values.classifications.map(item => item.classification.id)
  var currentClassifications = values.classifications
  newClassification.forEach(classification => {
    if (!currentClassificationsIds.includes(classification)) {
        currentClassifications.push({ classification: { id: classification }, contacts: [] })
    }
  })
  currentClassificationsIds.forEach(currentClassificationId => {
    if (!newClassification.includes(currentClassificationId)) {
      const filteredClassifications = values?.classifications?.filter(f => f.classification.id !== currentClassificationId)
      currentClassifications = filteredClassifications
    }
  })
  setValues({...values, classifications: currentClassifications})
}

export function handleOpen(setOpen) {
  setOpen(true)
}

export function handleRemoveAddress(values, index, setValues) {
  const filteredAddresses = values.addresses.filter((_, i) => i !== index)
  setValues({
    ...values,
    addresses: filteredAddresses
  })
}

export function handleRemoveContact(classificationId, values, index, setValues) {
  var filteredValuesByClassificationId = values.classifications.find(f => f.classification.id === classificationId)
  var filteredContacts = filteredValuesByClassificationId.contacts.filter((_, contactIndex) => contactIndex !== index)
  filteredValuesByClassificationId.contacts = filteredContacts
  var makeValues = values?.classifications?.map(m =>
    m.classification.id === classificationId
      ? filteredValuesByClassificationId
      : m
  )
  setValues({...values, classifications: makeValues})
}

export function handleEditAddress(address, index, setAddressForm, setVisibleForm) {
  setAddressForm({
    ...address,
    index: index
  })
  setVisibleForm(true)
}

export function handleEditContact(contact, index, setContactsForm, setVisibleForm) {
  setContactsForm({
    ...contact,
    index: index
  })
  setVisibleForm(true)
}

export function handleAddForm(addressForm, values, setValues, handleCloseForm) {
  if (addressForm.index !== null) {
    const getAddresses = values.addresses
    getAddresses[addressForm.index] = addressForm
    setValues({...values, addresses: getAddresses})
  } else {
    const getAddresses = values.addresses
    getAddresses.push(addressForm)
    setValues({...values, addresses: getAddresses})
  }
  handleCloseForm()
}

export function handleAddContactForm(contactForm, values, setValues, classificationId, handleCloseForm) {
  var filteredValuesByClassificationId = values.classifications.find(f => f.classification.id === classificationId)
  if (contactForm.index !== null) {
    filteredValuesByClassificationId.contacts[contactForm.index] = contactForm
    var makeValues = values?.classifications?.map(m =>
      m.classification.id === classificationId
        ? filteredValuesByClassificationId
        : m
    )
    setValues({...values, classifications: makeValues})
  } else {
    filteredValuesByClassificationId.contacts.push(contactForm)
    var makeValues = values?.classifications?.map(m =>
      m.classification.id === classificationId
        ? filteredValuesByClassificationId
        : m
    )
    setValues({...values, classifications: makeValues})
  }
  handleCloseForm()
}

export function filteredAddressType(addressTypeList, values) {
  var filteredAddressTypeList = addressTypeList
  if (values?.some(address =>address.addressType.id == ADDRESS_TYPE.PRINCIPAL)) {
    filteredAddressTypeList = addressTypeList?.filter(addressType => addressType.id !== ADDRESS_TYPE.PRINCIPAL)
  }
  return filteredAddressTypeList
}

export function filteredContacts(classificationId, classifications) {
  return classifications.find(f => f.classification.id === classificationId)?.contacts
}

export function handleCloseForm(setAddressForm, setVisibleForm) {
  setAddressForm({
    index: null,
    postalCode: '',
    number: null,
    name: '',
    neighborhood: '',
    complement: '',
    cityId: null,
    stateId: null,
    countryId: null,
    addressType: { id: null }
  })
  setVisibleForm(false)
}

export function handleCloseContactForm(setContactForm, setVisibleContactForm) {
  setContactForm({
    index: null,
    name: '',
    email: '',
    phone: '',
    isActive: true
  })
  setVisibleContactForm(false)
}
