import { DOCUMENT_TYPE } from "@/main/enums";

export function allowOnlyNumbers(input: string) {
  return input.replace(/\D/g, "");
}

export function formatPhoneNumber(input) {
  const digits = input.replace(/\D/g, "");

  if (digits.length === 11 && digits[2] === '9') {
      return digits.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
  } else  {
      return digits.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
  }
}

export function makeDocumentFieldType(documentTypeId, document) {
  switch (documentTypeId) {
    case DOCUMENT_TYPE.CNPJ:
      return document?.replace(/\D/g, '')
        .replace(/^(\d{2})(\d)/, "$1.$2")
        .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
        .replace(/\.(\d{3})(\d)/, ".$1/$2")
        .replace(/(\d{4})(\d)/, "$1-$2");
    case DOCUMENT_TYPE.CPF:
      return document?.replace(/\D/g, '')
              .replace(/(\d{3})(\d)/, "$1.$2")
              .replace(/(\d{3})\.(\d{3})(\d)/, "$1.$2.$3")
              .replace(/(\d{3})-(\d{2})\d+?$/, "$1-$2");
    default:
      return '';
  }
}