import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { HttpClient, HttpStatusCode } from '@/domain/protocols/http'
import { LoadBodyworkList } from '@/domain/usecase'
import { RemoteLoadBodyworkListDTO } from '@/data/models'

export class RemoteLoadBodyworkList implements LoadBodyworkList {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadBodyworkList.Model>
  ) {}

  async load (filters: Object): Promise<LoadBodyworkList.Model> {
    const queryString = Object.entries(filters)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');
    const httpResponse = await this.httpClient.request({
      url: `${this.url}?${queryString}`,
      method: 'get'
    })
    const LoadBodyworkList = httpResponse.body
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return LoadBodyworkList
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default:
        //@ts-ignore
        const err = httpResponse.body?.response?.error;
        throw new UnexpectedError(err);
    }
  }
}

export namespace RemoteLoadBodyworkList {
  export type Model = RemoteLoadBodyworkListDTO
}
