import React, { useEffect, useState } from "react";
import {
  AddBusinessPartner as DomainAddBusinessPartner,
  LoadBusinessPartnerList,
  LoadClassificationList,
  LoadCountriesList,
  LoadAddressTypeList,
  LoadDocumentTypeList,
  LoadCitiesList,
  LoadStatesList,
  LoadBusinessPartner,
  UpdateBusinessPartner as DomainUpdateBusinessPartner
} from "@/domain/usecase";
import { LabelCell, Pagination, Table, ProgressIndicator, Tab } from "fiorde-fe-components";
import { useQuery } from "@tanstack/react-query";
import {
  AddBusinessPartner,
  PageHeader,
} from "@/presentation/components";
import { t } from "i18next";
import { UpdateBusinessPartner } from "@/presentation/components/business-partner/update-business-partner";
import { DisableBusinessPartner } from "@/presentation/components/business-partner/disable-business-partner";
import { 
  PREFIX_BUSINESSPARTNER_LISTBUSINESSPARTNER_LABELCELL_SIMPLENAME,
  PREFIX_BUSINESSPARTNER_LISTBUSINESSPARTNER_LABELCELL_DOCUMENT,
 } from "@/ids";
import { DOCUMENT_TYPE } from "@/main/enums";
import { makeDocumentFieldType } from "@/presentation/utils/replace";

type BusinessPartnerListProps = {
  loadBusinessPartnerList: LoadBusinessPartnerList;
  loadClassificationList: LoadClassificationList;
  addBusinessPartner: DomainAddBusinessPartner;
  loadCountriesList: LoadCountriesList;
  loadAddressTypeList: LoadAddressTypeList
  loadDocumentTypeList: LoadDocumentTypeList
  loadCitiesList: LoadCitiesList
  loadStatesList: LoadStatesList
  loadBusinessPartner: LoadBusinessPartner
  updateBusinessPartner: DomainUpdateBusinessPartner
};

const columns = ({
  loadBusinessPartner,
  updateBusinessPartner,
  loadClassificationList,
  loadCountriesList,
  loadAddressTypeList,
  loadDocumentTypeList,
  loadCitiesList,
  loadStatesList
}) => [
  {
    label: t("page.business-partner-list.columns.name"),
    key: "simpleName",
    render: ({ simpleName }: any) => (
      <LabelCell id={PREFIX_BUSINESSPARTNER_LISTBUSINESSPARTNER_LABELCELL_SIMPLENAME}>{simpleName}</LabelCell>
    ),
    size: 2.5,
  },
  {
    label: t("page.business-partner-list.columns.document"),
    key: "document",
    render: ({ document, documentType }: any) => (
      <LabelCell id={PREFIX_BUSINESSPARTNER_LISTBUSINESSPARTNER_LABELCELL_DOCUMENT}>{makeDocumentFieldType(documentType.id, document)}</LabelCell>
    ),
    size: 2.5,
  },
  {
    label: t("page.business-partner-list.columns.menu"),
    key: "menu",
    render: ({ id, isActive }) => (
      <div style={{ display: "flex", gap: 8 }}>
        <UpdateBusinessPartner
          id={id}
          loadBusinessPartner={loadBusinessPartner}
          updateBusinessPartner={updateBusinessPartner} 
          loadClassificationList={loadClassificationList}
          loadCountriesList={loadCountriesList}
          loadAddressTypeList={loadAddressTypeList}
          loadDocumentTypeList={loadDocumentTypeList}
          loadCitiesList={loadCitiesList}
          loadStatesList={loadStatesList}
        />
        <DisableBusinessPartner
          id={id}
          checked={isActive}
          loadBusinessPartner={loadBusinessPartner}
          updateBusinessPartner={updateBusinessPartner}
        />
      </div>
    ),
    size: 0.5,
  },
];

export function BusinessPartner({
  loadBusinessPartnerList,
  loadClassificationList,
  addBusinessPartner,
  loadCountriesList,
  loadAddressTypeList,
  loadDocumentTypeList,
  loadStatesList,
  loadCitiesList,
  loadBusinessPartner,
  updateBusinessPartner
}: BusinessPartnerListProps) {
  const [filter, setFilter] = useState({
    page: 0,
    size: 10,
    sort: "id,desc",
    classificationId: 1
  });
  const { isLoading, isRefetching, data } = useQuery({
    queryKey: ["business-partner-list", filter],
    queryFn: async () => loadBusinessPartnerList.load(filter)
  });
  const { data: classificationList } = useQuery({
    queryKey: ["classification-list"],
    queryFn: async () => loadClassificationList.load(),
  });
  function handleTabChange (newClassification) {
    setFilter({ ...filter, page: 0, classificationId: newClassification })
  }
  return (
    <div>
      <ProgressIndicator
        visible={isLoading || isRefetching}
        content={t("components.progress-indicator.content")}
      />
      <PageHeader title={t("page.business-partner-list.header")}>
        <AddBusinessPartner 
          addBusinessPartner={addBusinessPartner} 
          loadClassificationList={loadClassificationList}
          loadCountriesList={loadCountriesList}
          loadAddressTypeList={loadAddressTypeList}
          loadDocumentTypeList={loadDocumentTypeList}
          loadCitiesList={loadCitiesList}
          loadStatesList={loadStatesList}
        />
      </PageHeader>
      <Tab onChange={handleTabChange} value={filter.classificationId}>
        {classificationList?.sort((a, b) => a.name.localeCompare(b.name))?.map(classification => 
          <Tab.Item
            key={classification.id}
            value={classification.id}
            title={classification.name}
            data-testid={`tab-${classification.id}`}
          />
        )}
      </Tab>
      <Table
        rows={data?.content || []}
        columns={columns({
          loadBusinessPartner,
          updateBusinessPartner,
          loadClassificationList,
          loadCountriesList,
          loadAddressTypeList,
          loadDocumentTypeList,
          loadCitiesList,
          loadStatesList
        })}
      />
      <Pagination
        count={data?.totalElements || 0}
        labelDisplay="exibindo"
        labelDisplayedRows="de"
        labelRowsPerPage="Parceiro de negócio por página"
        onPageChange={(value) =>
          setFilter((filter: any) => ({ ...filter, page: value }))
        }
        onRowsPerPageChange={(value) =>
          setFilter((filter: any) => ({
            ...filter,
            size: value,
            page: 0,
          }))
        }
        tooltipBack="Anterior"
        tooltipFirst="Primeira"
        tooltipLast="Última"
        tooltipNext="Próxima"
        page={filter.page}
      />
    </div>
  );
}
