import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { AddBusinessPartner } from "@/domain/usecase";
import { RemoteAddBusinessPartnerDTO } from "../models";
import { AccessDeniedError, UnexpectedError } from "@/domain/errors";

export class RemoteAddBusinessPartner implements AddBusinessPartner {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<AddBusinessPartner.Model>
  ) {}

  async add(params: AddBusinessPartner.Model): Promise<AddBusinessPartner.Model> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: params,
    });
    const AddBusinessPartner = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.created:
        return AddBusinessPartner;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        //@ts-ignore
        const err = httpResponse.body?.response?.error;
        throw new UnexpectedError(err);
    }
  }
}

export namespace RemoteAddBusinessPartner {
  export type Model = RemoteAddBusinessPartnerDTO;
}
