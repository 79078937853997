import { useEffect, useState } from "react";
import { UpdateBodywork as DomainUpdateBodywork, LoadBodywork } from "@/domain/usecase";
import { Button, Modal, Messages, IconButton, ProgressIndicator, Switch } from "fiorde-fe-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import {
  PREFIX_BODYWORK_DISABLEBODYWORK_BUTTON_CLOSE,
  PREFIX_BODYWORK_DISABLEBODYWORK_BUTTON_SUBMIT,
} from "@/ids";

type DisableBodyworkProps = {
  updateBodywork: DomainUpdateBodywork
  loadBodywork: LoadBodywork
  id: number
  checked: boolean
}

export function DisableBodywork({ updateBodywork, loadBodywork, id, checked }: DisableBodyworkProps) {
  const queryClient = useQueryClient()
  const [open, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: ""
  })

  const { data } = useQuery<any>({
    queryKey: [`bodywork-${id}`],
    queryFn: async () => await loadBodywork.load(id),
    enabled: open
  })

  function handleSubmit (): void {
    setLoading(true)
    updateBodywork.update(id, {
      ...data,
      isActive: !checked
    })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['bodywork-list'] })
        handleClose()
        setResponseMessage({
          message: checked
            ? t("components.disable-bodywork.response-success-message.active")
            : t("components.disable-bodywork.response-success-message.disabled"),
          severity: "success"
        })
      })
      .catch(err => {
        console.log(err)
        setResponseMessage({
          message: err?.message,
          severity: "error"
        })
      })
      .finally((() => {
        setLoading(false)
      }))
  }

  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
    queryClient.invalidateQueries({ queryKey: [`bodywork-${id}`] })
  }

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      <IconButton
        data-testid="open-button"
        onClick={handleOpen}
        tooltip={checked
          ? t("components.disable-bodywork.open-button-tooltip.active")
          : t("components.disable-bodywork.open-button-tooltip.disabled")
        }
      >
        <Switch checked={checked} />
      </IconButton>
      <Modal
        width={600}
        title={checked
          ? t("components.disable-bodywork.title.active")
          : t("components.disable-bodywork.title.disabled")
        }
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_BODYWORK_DISABLEBODYWORK_BUTTON_CLOSE}
            data-testid='close-button'
            backgroundGreen={false}
            noBackground
            onAction={() => handleClose()}
            text={t("components.disable-bodywork.close-button")}
          />,
          <Button
            id={PREFIX_BODYWORK_DISABLEBODYWORK_BUTTON_SUBMIT}
            data-testid='submit-button'
            onAction={() => handleSubmit()}
            text={t("components.disable-bodywork.submit-button")}
          />
        ]}
      >
        <span>
          {checked
            ? t("components.disable-bodywork.content.active")
            : t("components.disable-bodywork.content.disabled")
          } {data?.name}?
        </span>
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={()=> setResponseMessage({
            message: "",
            severity: ""
          })}
        />
      )}
    </>
  );
}
