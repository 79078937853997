import { useEffect, useState } from "react";
import { UpdateBodywork as DomainUpdateBodywork, LoadBodywork } from "@/domain/usecase";
import { Button, Modal, FormControl, Messages, Input, IconButton, ProgressIndicator } from "fiorde-fe-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EditIcon } from "@/presentation/components";
import { t } from "i18next";
import { PREFIX_BODYWORK_UPDATEBODYWORK_BUTTON_CLOSE,
  PREFIX_BODYWORK_UPDATEBODYWORK_BUTTON_SUBMIT,
  PREFIX_BODYWORK_UPDATEBODYWORK_INPUT_NAME,
  PREFIX_BODYWORK_UPDATEBODYWORK_INPUT_NAME_LABEL
 } from "@/ids";

type UpdateBodyworkProps = {
  updateBodywork: DomainUpdateBodywork
  loadBodywork: LoadBodywork
  id: number
}

export function UpdateBodywork({ updateBodywork, loadBodywork, id }: UpdateBodyworkProps) {
  const queryClient = useQueryClient()
  const [name, setTxBodywork] = useState("")
  const [open, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: ""
  })

  const { data } = useQuery<any>({
    queryKey: [`bodywork-${id}`],
    queryFn: async () => await loadBodywork.load(id),
    enabled: open
  })

  function handleSubmit (): void {
    setLoading(true)
    updateBodywork.update(id, {
      ...data, name: name })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['bodywork-list'] })
        handleClose()
        setResponseMessage({
          message: t("components.update-bodywork.response-success-message"),
          severity: "success"
        })
      })
      .catch(err => {
        setResponseMessage({
          message: err?.message,
          severity: "error"
        })
      })
      .finally((() => {
        setLoading(false)
      }))
  }

  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
    queryClient.invalidateQueries({ queryKey: [`bodywork-${id}`] })
  }

  useEffect(() => {
    if (open) {
      queryClient.invalidateQueries({ queryKey: [`bodywork-${id}`]})
    }
  },[open])

  useEffect(() => {
    setTxBodywork(data?.name)
  }, [data])

  function handleDisableSubmit() {
    if (name === '') return true
    return false
  }

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      <IconButton
        data-testid="open-button"
        onClick={handleOpen}
        tooltip={t("components.update-bodywork.open-button-tooltip")}
      >
        <EditIcon />
      </IconButton>
      <Modal
        width={600}
        title={t("components.update-bodywork.title")}
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_BODYWORK_UPDATEBODYWORK_BUTTON_CLOSE}
            data-testid='close-button'
            backgroundGreen={false}
            noBackground
            onAction={() => handleClose()}
            text={t("components.update-bodywork.close-button")}
          />,
          <Button
            id={PREFIX_BODYWORK_UPDATEBODYWORK_BUTTON_SUBMIT}
            data-testid='submit-button'
            onAction={() => handleSubmit()}
            disabled={handleDisableSubmit()}
            text={t("components.update-bodywork.submit-button")}
          />
        ]}
      >
        <FormControl
          label={t("components.update-bodywork.name-label")}
          id={PREFIX_BODYWORK_UPDATEBODYWORK_INPUT_NAME_LABEL}
          required
        >
          <Input
            inputProps={{ 'data-testid': 'name' }}
            id={PREFIX_BODYWORK_UPDATEBODYWORK_INPUT_NAME}
            onChange={(value) => setTxBodywork(value)}
            value={name}
            placeholder={t("components.update-bodywork.name-placeholder")}
            maxLength={100}
          />
        </FormControl>
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={()=> setResponseMessage({
            message: "",
            severity: ""
          })}
        />
      )}
    </>
  );
}
