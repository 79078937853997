import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import {
  HttpClient,
  HttpStatusCode,
} from "@/domain/protocols/http";
import { LoadCountriesList } from "@/domain/usecase";
import { RemoteLoadCountriesListDTO } from "../models";

export class RemoteLoadCountriesList implements LoadCountriesList {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadCountriesList.Model>
  ) {}

  async load(): Promise<LoadCountriesList.Model> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "get",
    });
    const LoadClassificationList = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return LoadClassificationList;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        //@ts-ignore
        const err = httpResponse.body?.response?.error;
        throw new UnexpectedError(err);
    }
  }
}

export namespace RemoteLoadCountriesList {
  export type Model = RemoteLoadCountriesListDTO;
}
