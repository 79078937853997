import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import {
  HttpClient,
  HttpStatusCode,
} from "@/domain/protocols/http";
import { LoadStatesList } from "@/domain/usecase";
import { RemoteLoadStatesListDTO } from "../models";

export class RemoteLoadStatesList implements LoadStatesList {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadStatesList.Model>
  ) {}

  async load(countryId: number): Promise<LoadStatesList.Model> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${countryId}/states`,
      method: "get",
    });
    const LoadClassificationList = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return LoadClassificationList;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        //@ts-ignore
        const err = httpResponse.body?.response?.error;
        throw new UnexpectedError(err);
    }
  }
}

export namespace RemoteLoadStatesList {
  export type Model = RemoteLoadStatesListDTO;
}
