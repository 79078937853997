import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { UpdateBusinessPartner } from "@/domain/usecase";
import { RemoteUpdateBusinessPartnerDTO } from "../models";
import { AccessDeniedError, UnexpectedError } from "@/domain/errors";

export class RemoteUpdateBusinessPartner implements UpdateBusinessPartner {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<UpdateBusinessPartner.Model>
  ) {}

  async update(
    id: number,
    body: UpdateBusinessPartner.Model
  ): Promise<UpdateBusinessPartner.Model> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${String(id)}`,
      method: "put",
      body: body,
    });
    const UpdateBusinessPartner = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return UpdateBusinessPartner;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        //@ts-ignore
        const err = httpResponse.body?.response?.error;
        throw new UnexpectedError(err);
    }
  }
}

export namespace RemoteUpdateBusinessPartner {
  export type Model = RemoteUpdateBusinessPartnerDTO;
}
