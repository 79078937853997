import React, { useState } from "react";
import { AddOrganization as DomainAddOrganization } from "@/domain/usecase";
import {
  Button,
  Modal,
  FormControl,
  Input,
  Messages,
} from "fiorde-fe-components";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { 
  PREFIX_ORGANIZATION_ADDORGANIZATION_BUTTON_OPEN,
  PREFIX_ORGANIZATION_ADDORGANIZATION_BUTTON_CLOSE,
  PREFIX_ORGANIZATION_ADDORGANIZATION_BUTTON_SUBMIT,
  PREFIX_ORGANIZATION_ADDORGANIZATION_INPUT_NAME,
  PREFIX_ORGANIZATION_ADDORGANIZATION_INPUT_NAME_LABEL
 } from "@/ids";

type AddOrganizationProps = {
  addOrganization: DomainAddOrganization;
};

export function AddOrganization({ addOrganization }: AddOrganizationProps) {
  const queryClient = useQueryClient();
  const [name, setname] = useState("");
  const [open, setOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  });

  function handleSubmit(): void {
    addOrganization
      .add({ name, isActive: true })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["organization-list"] });
        handleClose();
        setResponseMessage({
          message: t("components.add-organization.response-success-message"),
          severity: "success",
        });
      })
      .catch((err) => {
        setResponseMessage({
          message: err?.message,
          severity: "error",
        });
      });
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setname("");
    setResponseMessage({
      message: "",
      severity: "",
    });
    setOpen(false);
  }

  return (
    <>
      <Button
        id={PREFIX_ORGANIZATION_ADDORGANIZATION_BUTTON_OPEN}
        icon="add"
        data-testid="open-button"
        onAction={() => handleOpen()}
        text={t("components.add-organization.open-button")}
      />
      <Modal
        data-testid="modal-add-organization"
        width={600}
        title={t("components.add-organization.title")}
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_ORGANIZATION_ADDORGANIZATION_BUTTON_CLOSE}
            data-testid="close-button"
            backgroundGreen={false}
            noBackground
            onAction={() => handleClose()}
            text={t("components.add-organization.close-button")}
          />,
          <Button
            id={PREFIX_ORGANIZATION_ADDORGANIZATION_BUTTON_SUBMIT}
            data-testid="submit-button"
            onAction={() => handleSubmit()}
            text={t("components.add-organization.submit-button")}
          />,
        ]}
      >
        <FormControl 
          label={t("components.add-organization.name-label")}
          id={PREFIX_ORGANIZATION_ADDORGANIZATION_INPUT_NAME_LABEL}
        >
          <Input
            inputProps={{ "data-testid": "name" }}
            id={PREFIX_ORGANIZATION_ADDORGANIZATION_INPUT_NAME}
            onChange={(value) => setname(value)}
            value={name}
            placeholder={t("components.add-organization.name-placeholder")}
          />
        </FormControl>
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
}
