import { useState } from "react";
import { AddBodywork as DomainAddBodywork } from "@/domain/usecase";
import { Button, Modal, FormControl, Input, Messages, ProgressIndicator } from "fiorde-fe-components";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import {
  PREFIX_BODYWORK_ADDBODYWORK_BUTTON_OPEN,
  PREFIX_BODYWORK_ADDBODYWORK_BUTTON_CLOSE,
  PREFIX_BODYWORK_ADDBODYWORK_BUTTON_SUBMIT,
  PREFIX_BODYWORK_ADDBODYWORK_INPUT_NAME,
  PREFIX_BODYWORK_ADDBODYWORK_INPUT_NAME_LABEL,
} from "@/ids";

type AddBodyworkProps = {
  addBodywork: DomainAddBodywork
}

export function AddBodywork({ addBodywork }: AddBodyworkProps) {
  const queryClient = useQueryClient()
  const [isLoading, setLoading] = useState(false)
  const [name, setTxBodywork] = useState("")
  const [open, setOpen] = useState(false)
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: ""
  })

  function handleSubmit (): void {
    setLoading(true)
    addBodywork.add({ name, isActive: true })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["bodywork-list"] })
        handleClose()
        setResponseMessage({
          message: t("components.add-vehicle-type.response-success-message"),
          severity: "success"
        })
      })
      .catch(err => {
        setResponseMessage({
          message: err?.message,
          severity: "error"
        })
      })
      .finally((() => {
        setLoading(false)
      }))
  }

  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setTxBodywork("")
    setResponseMessage(({
      message: "",
      severity: ""
    }))
    setOpen(false)
  }

  function handleDisableSubmit() {
    if (name === '') return true
    return false
  }

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      <Button
        id={PREFIX_BODYWORK_ADDBODYWORK_BUTTON_OPEN}
        icon="add"
        data-testid="open-button"
        onAction={() => handleOpen()}
        text={t("components.add-bodywork.open-button")}
      />
      <Modal
        data-testid="modal-add-bodywork"
        width={600}
        title={t("components.add-bodywork.open-button")}
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_BODYWORK_ADDBODYWORK_BUTTON_CLOSE}
            data-testid="close-button"
            backgroundGreen={false}
            noBackground
            onAction={() => handleClose()}
            text={t("components.add-bodywork.close-button")}
          />,
          <Button
            id={PREFIX_BODYWORK_ADDBODYWORK_BUTTON_SUBMIT}
            data-testid="submit-button"
            onAction={() => handleSubmit()}
            disabled={handleDisableSubmit()}
            text={t("components.add-bodywork.submit-button")}
          />
        ]}
      >
        <FormControl
          label={t("components.add-bodywork.name-label")}
          id={PREFIX_BODYWORK_ADDBODYWORK_INPUT_NAME_LABEL}
          required  
        >
          <Input
            id={PREFIX_BODYWORK_ADDBODYWORK_INPUT_NAME}
            inputProps={{ "data-testid": "name" }}
            onChange={(value) => setTxBodywork(value)}
            value={name}
            placeholder={t("components.add-bodywork.name-placeholder")}
            maxLength={100}
          />
        </FormControl>
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={()=> setResponseMessage({
            message: "",
            severity: ""
          })}
        />
      )}
    </>
  );
}
