import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { HttpClient, HttpStatusCode } from '@/domain/protocols/http'
import { LoadBusinessPartner } from '@/domain/usecase'
import { RemoteLoadBusinessPartnerDTO } from '@/data/models'

export class RemoteLoadBusinessPartner implements LoadBusinessPartner {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadBusinessPartner.Model>
  ) {}

  async load (id: number): Promise<LoadBusinessPartner.Model> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: 'get'
    })
    const LoadBusinessPartner = httpResponse.body
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return LoadBusinessPartner
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default:
        //@ts-ignore
        const err = httpResponse.body?.response?.error;
        throw new UnexpectedError(err);
    }
  }
}

export namespace RemoteLoadBusinessPartner {
  export type Model = RemoteLoadBusinessPartnerDTO
}
