import React from 'react'
import { Row } from '../row/row'
import {
  FormControl,
  Input,
  INPUT_TYPE,
  Select,
  Switch
} from "fiorde-fe-components"
import { makeSelectValues } from '@/presentation/utils/arrays'
import { addClassification } from './business-partner-helpers'
import { t } from 'i18next'
import {
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_SIMPLENAME,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_CORPORATENAME,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_DOCUMENT,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_REFERENCE,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_SIMPLENAME_LABEL,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_CORPORATENAME_LABEL,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_DOCUMENT_LABEL,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_REFERENCE_LABEL,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SELECT_CLASSIFICATION_LABEL,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SELECT_CLASSIFICATION,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SELECT_DOCUMENTTYPE_LABEL,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SELECT_DOCUMENTTYPE,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SWITCH_HEADOFFICE_LABEL,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SWITCH_HEADOFFICE,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SELECT_COUNTRY_LABEL,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SELECT_COUNTRY,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SELECT_CITY_LABEL,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SELECT_CITY,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SELECT_STATE_LABEL,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SELECT_STATE
} from '@/ids'
import { DOCUMENT_TYPE } from '@/main/enums'
import { allowOnlyNumbers } from '@/presentation/utils/replace'

export function BusinessPartnerForm({
  values,
  onChange,
  classificationList,
  documentTypeList,
  countriesList,
  statesList,
  citiesList
}) {
  function makeDocumentFieldType() {
    switch (values?.documentType?.id) {
      case DOCUMENT_TYPE.CNPJ:
        return INPUT_TYPE.CNPJ
      case DOCUMENT_TYPE.CPF:
        return INPUT_TYPE.CPF
      return INPUT_TYPE.DEFAULT
    }
  }
  return (
    <>
      <FormControl
        label={t("components.business-partner-form.add-classification-label")}
        id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SELECT_CLASSIFICATION_LABEL}
        required
      >
        <Select
          id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SELECT_CLASSIFICATION}
          name='classification'
          options={makeSelectValues({
            label: 'name',
            value: 'id',
            array: classificationList
          })}
          onChange={(value) => addClassification(values, value, onChange)}
          placeholder={t("components.business-partner-form.add-classification-placeholder")}
          multiple
          value={values?.classifications?.map(item => item?.classification?.id)}
        />
        </FormControl>
        <FormControl
          label={t("components.business-partner-form.simple-name-label")}
          id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_SIMPLENAME_LABEL}
          required
        >
          <Input
            inputProps={{ "data-testid": "simpleName" }}
            id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_SIMPLENAME}
            onChange={(value: any) => onChange({...values, simpleName: value})}
            value={values?.simpleName}
            placeholder={t("components.business-partner-form.simple-name-placeholder")}
            maxLength={100}
          />
        </FormControl>
        <FormControl
          label={t("components.business-partner-form.corporate-name-label")}
          id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_CORPORATENAME_LABEL}
          required
        >
          <Input
            inputProps={{ "data-testid": "corporateName" }}
            id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_CORPORATENAME}
            onChange={(value: any) => onChange({...values, corporateName: value})}
            value={values?.corporateName}
            placeholder={t("components.business-partner-form.corporate-name-placeholder")}
            maxLength={100}
          />
        </FormControl>
        <Row gridTemplateColumns='1fr 1fr'>
          <FormControl
            label={t("components.business-partner-form.document-type-label")}
            id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SELECT_DOCUMENTTYPE_LABEL}
            required
          >
            <Select
              id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SELECT_DOCUMENTTYPE}
              name='classification'
              options={makeSelectValues({
                label: 'name',
                value: 'id',
                array: documentTypeList
              })}
              onChange={(value) => onChange({...values, documentType: { id: value }, document: '' })}
              value={values?.documentType?.id}
              placeholder={t("components.business-partner-form.document-type-placeholder")}
            />
          </FormControl>
          <FormControl
            label={t("components.business-partner-form.document-label")}
            id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_DOCUMENT_LABEL}
            required
          >
            <Input
              inputProps={{ "data-testid": "document" }}
              id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_DOCUMENT}
              onChange={(value: any) => onChange({...values, document: allowOnlyNumbers(value)})}
              value={values?.document}
              placeholder={t("components.business-partner-form.document-placeholder")}
              type={makeDocumentFieldType()}
            />
          </FormControl>
        </Row>
        <Row gridTemplateColumns='1fr 1fr'>
          <FormControl
            label={t("components.business-partner-form.reference-label")}
            id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_REFERENCE_LABEL}
          >
            <Input
              inputProps={{ "data-testid": "reference" }}
              id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_REFERENCE}
              onChange={(value: any) => onChange({...values, reference: value})}
              value={values?.reference}
              placeholder={t("components.business-partner-form.reference-placeholder")}
              type={INPUT_TYPE.ALPHANUMERIC}
              maxLength={20}
            />
          </FormControl>
          <FormControl
            label={t("components.business-partner-form.head-office-label")}
            id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SWITCH_HEADOFFICE_LABEL}
          >
            <Switch
              id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SWITCH_HEADOFFICE}
              label={values?.isHeadOffice
                ? t("components.business-partner-form.head-office-true")
                : t("components.business-partner-form.head-office-false")}
              checked={values?.isHeadOffice}
              onChange={() => onChange({...values, isHeadOffice: !values?.isHeadOffice})}
            />
          </FormControl>
        </Row>
        <Row gridTemplateColumns='1fr 1fr 1fr' gap="16px">
        <FormControl
          label={t("components.business-partner-form.country-label")}
          id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SELECT_COUNTRY_LABEL}
          required
        >
          <Select
            id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SELECT_COUNTRY}
            name='country'
            options={makeSelectValues({
              label: 'name',
              value: 'id',
              array: countriesList
            })}
            onChange={(value) => onChange({ ...values, countryId: value, stateId: null, cityId: null })}
            value={values?.countryId}
            placeholder={t("components.business-partner-form.country-placeholder")}
          />
        </FormControl>
        <FormControl
          label={t("components.business-partner-form.state-label")}
          id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SELECT_STATE_LABEL}
          required
        >
          <Select
            id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SELECT_STATE}
            name='state'
            options={makeSelectValues({
              label: 'name',
              value: 'id',
              array: statesList
            })}
            onChange={(value) => onChange({ ...values, stateId: value, cityId: null })}
            value={values?.stateId}
            placeholder={t("components.business-partner-form.state-placeholder")}
          />
        </FormControl>
        <FormControl
          label={t("components.business-partner-form.city-label")}
          id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SELECT_CITY_LABEL}
          required
        >
          <Select
            id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_SELECT_CITY}
            name='city'
            options={makeSelectValues({
              label: 'name',
              value: 'id',
              array: citiesList
            })}
            onChange={(value) => onChange({ ...values, cityId: value })}
            value={values?.cityId}
            placeholder={t("components.business-partner-form.city-placeholder")}
          />
        </FormControl>
      </Row>
    </>
  )
}